<template>
  <VBackdrop @click="props.closeViaBackdrop && emit('close')">
    <div role="dialog" class="modern-color-theme font-poppins mx-8 p-6 bg-white rounded-2xl flex flex-col shadow-modal" :class="{ 'relative': props.closeViaButton }" :style="computedLayout" v-bind="$attrs" data-component-name="VDialog" @click.stop>
      <VButton v-if="props.closeViaButton" variant="text" color="neutral" icon :container-props="{ class: 'absolute top-2 right-2' }" @click="emit('close')">
        <VIcon name="Solid/close" />
      </VButton>
      <slot />
      <div v-if="slots.buttons" class="flex justify-end gap-6">
        <slot name="buttons" />
      </div>
    </div>
  </VBackdrop>
</template>
<script setup lang="ts">
import VButton from '@component-library/buttons/VButton.vue';
import VBackdrop from '../layouts/VBackdrop.vue'
import VIcon from '@component-library/labels/VIcon.vue';
import { computed, useSlots } from 'vue';

defineOptions({
  name: 'VDialog',
  inheritAttrs: false
})

const props = withDefaults(
  defineProps<{
    width?: string
    height?: string
    closeViaButton?: boolean
    closeViaBackdrop?: boolean
  }>(),
  {
    width: '500px',
    height: undefined,
    closeViaBackdrop: false,
    closeViaButton: false
  }
)

const emit = defineEmits<{
  close: []
}>()

const slots = useSlots()

const computedLayout = computed(() => {
  return {
    minWidth: props.width,
    width: props.width,
    minHeight: props.height,
    height: props.height
  }
})
</script>
